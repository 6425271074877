<template>
  <div class="w-28" @click.prevent.stop>
    <el-dropdown trigger="click" class="w-full">
      <el-button
        v-loading="isStatusLoading"
        size="mini"
        data-testid="purchase-order-status"
        :type="currentStatus.type"
        :disabled="isReadOnly"
        class="py-1 text-white w-full"
      >
        {{ currentStatus.label }}
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="status in statusOptions"
            :key="status.value"
            @click="handelStatusChange(status.value)"
          >
            <el-button
              size="mini"
              :type="status.type"
              class="py-1 text-white w-full"
              v-text="status.label"
            />
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { statusOptions } from '../helpers'

export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localStatus: this.scope.row.status,
      statusOptions: statusOptions(this),
      isStatusLoading: false
    }
  },
  computed: {
    currentStatus() {
      return this.statusOptions[this.localStatus] || this.statusOptions.draft
    },
    isReadOnly() {
      return !this.$checkPermissions({
        scopes: ['supplier_management:purchase_orders:send']
      })
    }
  },
  methods: {
    async handelStatusChange(status) {
      //if status is changed to sent, show the modal first  to confirm
      if (status === 'sent') {
        this.$emitter.emit('email-preview', this.scope.row)
      } else {
        this.changeStatus(status)
      }
    },
    async changeStatus(status) {
      if (this.localStatus === status) return
      this.$ampli.eventWithBaseProps('purchaseOrderStatusChangeFromList')
      try {
        this.isStatusLoading = true
        const { data = {} } = await th
          .purchaseOrders()
          .put(this.scope.row.id, { status })

        if (data.id) {
          //setup the state locally instead of refreshing the table for better UX
          this.localStatus = data.status
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.purchase_order.plural')
            })
          })
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.purchase_order.plural')
          })
        })
      } finally {
        this.isStatusLoading = false
      }
    }
  }
}
</script>
