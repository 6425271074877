<template>
  <el-button :disabled="disabled" @click.stop="handleClick">
    {{ $t('pages.purchase_order.all.headers.book_in') }}
  </el-button>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'

// TODO: Update eslint config so these errors are not thrown
// eslint-disable-next-line no-undef
const props = defineProps({
  scope: {
    type: Object,
    default: () => ({})
  }
})

const ampli = inject('ampli')

const disabled = computed(
  () =>
    props?.scope?.row?.status === 'done' ||
    props?.scope?.row?.status === 'draft'
)
const purchaseOrderId = computed(() => props?.scope?.row?.id)

const router = useRouter()

function handleClick() {
  ampli.eventWithBaseProps('purchaseorderBookinButtonClick')
  router.push({
    name: 'ims-stock-manager',
    query: { purchaseOrderId: purchaseOrderId.value }
  })
}
</script>
