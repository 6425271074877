<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="suppliers-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="italic h-full flex flex-col flex-end font-bold leading-7 text-lg max-w-lg"
      >
        <div v-text="$t('pages.purchase_order.empty.text.title')" />
        <div v-text="$t('pages.purchase_order.empty.text.sub')" />
      </div>
      <router-link :to="{ name: 'purchase-orders-new' }">
        <el-button
          v-permissions="['supplier_management:purchase_orders:create']"
        >
          {{ $t('common.actions.create') }}
        </el-button>
      </router-link>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-center justify-center">
      <img
        :src="require('@/assets/illustrations/customers-list-empty.svg')"
        alt="suppliers-list-empty"
        style="max-width: 80%; max-height: 71vh"
        loading="lazy"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
