<template>
  <th-page-wrapper>
    <purchase-orders-list-empty
      v-if="!purchaseOrdersStore.purchaseOrdersExists"
    />
    <th-datatable
      v-else
      ref="table"
      class="hotjar-ignore"
      do-route
      do-route-filters
      no-meta-check
      resource="purchaseOrders"
      route-base="/supplier-management/purchase-orders"
      show-search-filter
      sortable
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :buttons="computedButtons"
      :headers-config="headersConfig"
      :headers="headers"
      :labels="labels"
      :loading-text="loadingText"
      :locale="locale"
      :meta-options="resourceQuery"
      :resource-limit="100"
      :resource-query="{ query: resourceQuery }"
      :retry-options="retryOptions()"
      :search-filters="filtersList"
      :show-operations="true"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    >
      <!-- Operations -->
      <template #operations="scope">
        <div class="flex justify-end">
          <!-- Download PDF button -->
          <a
            target="_blank"
            class="px-3 mr-3 el-button--text-icon"
            download
            @click.stop="handleDownloadPDF(scope.row)"
          >
            <el-icon class="text-lg">
              <Document />
            </el-icon>
          </a>
        </div>
      </template>
    </th-datatable>
    <!-- Send Email preview Modal -->
    <purchase-order-send-modal />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import sortBy from 'just-sort-by'
import { applyFiltersBeforeRouteEnter } from '@/utils/date'
import { usePurchaseOrdersStore } from '@/store/purchaseOrders'
import PurchaseOrdersListEmpty from './empty'
import PurchaseOrderTableRowStatus from './components/purchase-order-table-row-status'
import PurchaseOrderTableRowBookIn from './components/purchase-order-table-row-book-in.vue'
import PurchaseOrderTableRowNotes from './components/purchase-order-table-row-notes.vue'
import PurchaseOrderSendModal from './components/purchase-order-send-modal'
import { statusOptions, downloadPurchaseOrderPDF } from './helpers'
import { useExportsStore } from '@/store/exports'

const headersConfigPath = 'settings.headerFilters.purchase_orders'

export default {
  name: 'PurchaseOrdersAll',
  metaInfo() {
    return {
      title: this.$t('common.resource.purchase_order.plural')
    }
  },
  components: {
    PurchaseOrdersListEmpty,
    PurchaseOrderSendModal
  },
  beforeRouteEnter: (to, _, next) => {
    const filters = {
      status: ['draft', 'sent'],
      date: undefined // small hack to prevent the default date filter
    }
    applyFiltersBeforeRouteEnter({
      path: to.path,
      query: to.query,
      next,
      filters
    })
  },
  setup() {
    const purchaseOrdersStore = usePurchaseOrdersStore()
    return { purchaseOrdersStore }
  },
  data() {
    return {
      resources: {},
      selectedProductOrderId: null,
      statusOptions: Object.values(statusOptions(this)),
      width: 500,
      headers: [
        {
          field: 'purchaseOrderNumber',
          label: this.$t('pages.purchase_order.headers.purchase_order_number'),
          fallback: '-',
          minWidth: 100,
          truncate: true
        },
        {
          field: 'supplier',
          label: this.$t('common.resource.supplier.singular'),
          minWidth: 100,
          truncate: true,
          formatter: (row) => safeGet(row, 'businessPartner.companyName', '-')
        },
        {
          field: 'totalAmount',
          label: this.$t('pages.purchase_order.all.headers.total'),
          minWidth: 70,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.totalAmount)) {
              return this.$formatCurrency(row.totalAmount, this.defaultCurrency)
            }

            return '-'
          }
        },
        {
          field: 'status',
          customRowComponent: PurchaseOrderTableRowStatus,
          label: this.$t('pages.purchase_order.all.headers.status'),
          minWidth: 130,
          truncate: true
        },
        {
          field: 'recipients',
          label: this.$t('common.forms.labels.email'),
          minWidth: 200,
          truncate: true,
          formatter: (row) => safeGet(row, 'recipients.0', '-')
        },
        {
          field: 'productsMissingQuantity',
          label: this.$t('pages.purchase_order.all.headers.open_delivery'),
          minWidth: 80,
          align: 'right'
        },
        {
          field: 'bookIn',
          customRowComponent: PurchaseOrderTableRowBookIn,
          label: this.$t('pages.purchase_order.all.headers.book_in'),
          minWidth: 140
        },
        {
          field: 'consignmentNotes',
          customRowComponent: PurchaseOrderTableRowNotes,
          label: this.$t('pages.purchase_order.all.headers.delivery_note'),
          minWidth: 140
        }
      ],
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      localConfiguration: 'Config/getLocalConfiguration',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      pageSize: 'Config/getPageSizes',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    resourceQuery() {
      return {
        deleted: false,
        orderNumber: this.orderNumber || undefined,
        businessPartnerId: this.businessPartnerId || undefined,
        status: this.status || undefined,
        size: this.pageSize.main
      }
    },
    labels() {
      return {
        table: {
          headers: {
            operations: this.$t('common.resource.purchase_order.singular')
          }
        }
      }
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['supplier_management:purchase_orders:create'],
          clickHandler: this.handleNewRequest
        },
        {
          type: 'custom_export',
          scopes: ['supplier_management:purchase_orders:export'],
          clickHandler: this.handleExport
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    headersConfig() {
      return safeGet(this.localConfiguration, headersConfigPath, {})
    },
    filtersList() {
      return [
        {
          name: 'orderNumber',
          type: 'input',
          label: this.$t(
            'pages.purchase_order.form.placeholder.purchase_order_number'
          )
        },
        {
          name: 'businessPartnerId',
          type: 'select',
          label: this.$t('common.resource.supplier.singular'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: this.suppliersOptions
        },
        {
          name: 'status',
          type: 'multiselect',
          label: this.$t('pages.purchase_order.all.headers.status'),
          options: this.statusOptions
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          preventDefaultDates: true,
          label: this.$t('pages.stock_movements.all.filters.daterange.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: ({ start, end }) => {
            // if end date is in the future, send now as the end date
            const now = new Date()
            const endDate = now < new Date(end) ? now.toISOString() : end
            return { start, end: endDate }
          },
          noFutureDates: true
        }
      ]
    },
    suppliersOptions() {
      return sortBy(this.resources.suppliers || [], 'companyName').map(
        ({ id, companyName }) => {
          return {
            value: id,
            label: companyName
          }
        }
      )
    }
  },
  mounted() {
    this.fetchResources()
    this.purchaseOrdersStore.checkPurchaseOrdersCount()
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
      this.purchaseOrdersStore.checkPurchaseOrdersCount()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async fetchResources() {
      this.resources = await this.$resourceFetch('suppliers')
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('common.resource.purchase_order.plural')
        })
      })
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')
      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    },
    handleNewRequest() {
      this.$ampli.eventWithBaseProps('purchaseOrderNewButtonClick')
      this.$router.push({ name: 'purchase-orders-new' })
    },
    async handleExport({ resourceOptions }) {
      this.$ampli.eventWithBaseProps('purchaseOrderExportButtonClick')
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv',
          filename_prefix: this.$t('nav.main.items.supplier.purchase_orders'),
          timezone: this.timeZone || 'Europe/Berlin'
        }
      }
      try {
        const { data: exportResult } = await th.purchaseOrders().export(query)

        if (!exportResult || !exportResult.url) {
          throw new Error(`Export API returned incorrect result`)
        }

        useExportsStore().setFinishedExport({
          exportResult,
          payload: {
            originKey: 'common.resource.purchase_order.plural',
            date: new Date(),
            action: {
              entity: 'purchase_orders',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('common.resource.purchase_order.plural')
          })
        })
      }
    },
    async handleDownloadPDF({ id }) {
      const error = await downloadPurchaseOrderPDF({ id })
      if (error) {
        this.$logException(error, {
          message: this.$t('notifications.pdf.download.fail')
        })
      }
    }
  }
}
</script>
