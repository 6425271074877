<template>
  <div class="w-32" @click.prevent.stop>
    <el-dropdown trigger="click" class="w-full">
      <el-button
        v-loading="isLoading"
        :disabled="noConsignmentNotes"
        size="mini"
        class="py-1 w-full"
      >
        {{ $t('pages.purchase_order.all.headers.delivery_note') }}
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="consignmentNote in consignmentNotes"
            :key="consignmentNote.id"
            @click="downloadConsignmentNote(consignmentNote)"
          >
            <el-button size="mini" class="py-1 w-full">
              {{ consignmentNote.consignmentNoteNumber }}
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import th from '@tillhub/javascript-sdk'

// TODO: Update eslint config so these errors are not thrown
// eslint-disable-next-line no-undef
const props = defineProps({
  scope: {
    type: Object,
    default: () => ({})
  }
})

const logException = inject('logException')

const isLoading = ref(false)
const consignmentNotes = computed(
  () => props?.scope?.row?.consignmentNotes ?? []
)
const noConsignmentNotes = computed(() => consignmentNotes.value.length === 0)

async function downloadConsignmentNote(consignmentNote) {
  try {
    isLoading.value = true
    const { data, contentType, filename } = await th
      .consignmentNotes()
      .pdfUri(consignmentNote?.id)
    const link = document.createElement('a')
    link.href = `data:${contentType};base64,${data}`
    link.download = filename
    link.click()
  } catch (err) {
    logException(err, { trackError: false })
  } finally {
    isLoading.value = false
  }
}
</script>
